import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';

import {RootRoutingModule} from './root-routing.module';
import {RootComponent} from './root.component';
import {SharedModule} from '@shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {HttpInterceptorService} from './http-interceptor.service';
import {NgxMaskModule} from 'ngx-mask';
import {TooltipModule} from '@cloudfactorydk/ng2-tooltip-directive';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import {SimplebarAngularModule} from 'simplebar-angular';
import {SyncSettingsService} from "@shared/services";
import {take} from "rxjs/operators";
import {Settings} from "@shared/store";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";

registerLocaleData(localePl, 'pl-PL', localePlExtra);

@NgModule({
  declarations: [
    RootComponent
  ],
  imports: [
    BrowserModule,
    SimplebarAngularModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([], {developmentMode: !environment.production}),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Frontend store',
      disabled: environment.production
    }),
    RootRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    RecaptchaV3Module,
    SharedModule,
    NgxMaskModule.forRoot(),
    TooltipModule.forRoot({"max-width": "450", "tooltip-class": "cl-tooltip"}),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [SyncSettingsService, HttpClient],
      useFactory: onAppInit,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pl-PL',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      // uwaga syntax useFactory(){} nie działa pod AOT, dlatego potrzeba useFactory: () => {}
      useFactory: (syncSettingsService: SyncSettingsService) => syncSettingsService.get().googleReCaptchaSiteKey,
      deps: [SyncSettingsService]
    }
  ],
  bootstrap: [RootComponent]
})
export class RootModule {
}

export function onAppInit(syncSettingsService: SyncSettingsService, http: HttpClient): () => Promise<any> {
  return (): Promise<any> => {
    return http.get('frontend/settings')
      .pipe(
        take(1)
      )
      .toPromise()
      .then((settings: Settings) => syncSettingsService.update(settings))
  };
}
