import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {Select} from "@ngxs/store";
import {SharedState} from "@shared/store";
import {Observable, Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {AutoUnsubscribe} from "@commonShared/utils/auto-unsubscribe";

@Injectable({
  providedIn: 'root'
})
@AutoUnsubscribe()
export class LoadJsService implements OnDestroy {
  private subscription: Subscription

  @Select(SharedState.isBrandGenerali)
  isBrandGenerali$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  loadJsScripts(production: boolean) {
    this.subscription = this.isBrandGenerali$
      .pipe(
        tap(isBrandGenerali => {
          if (isBrandGenerali) {
            this.loadGeneraliJsScripts(production);
          }
        }),
      ).subscribe();
  }

  loadGeneraliJsScripts(production: Boolean) {
    if (production) {
      this.appendToDOM('https://actionbot-proxy-generali.12lsd8yhnmgy.eu-de.codeengine.appdomain.cloud/actionbot/script/prod/actionbot.js');
    } else {
      this.appendToDOM('https://actionbot-proxy-generali.12lsd8yhnmgy.eu-de.codeengine.appdomain.cloud/actionbot/script/dev/actionbot.js');
    }
  }

  private appendToDOM(scriptUrl: string) {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;
    this.document.body.appendChild(script);
  }

  ngOnDestroy(): void {
    //puste ngOnDestroy jest wymagane przez @AutoUnsubscribe podczas budowania gdy AOT dostarcza danych o klasie a nie ją zmienia
  }
}
