import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

import {CustomValidators, Errors, GrammarMode} from '../utils';
import {CustomValidator} from '@shared/utils';
import {MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {CustomControl} from "@shared/form/custom.control";

export interface RowErrorsConfig {
  [key: string]: {
    grammarMode?: GrammarMode;
    customMsg?: string
  };
}

export const requiredFeminine: RowErrorsConfig = {
  required: {
    grammarMode: GrammarMode.FEMININE
  }
};

export const requiredNeuter: RowErrorsConfig = {
  required: {
    grammarMode: GrammarMode.NEUTER
  }
};

export const requiredMasculine: RowErrorsConfig = {
  required: {
    grammarMode: GrammarMode.MASCULINE
  }
};

@Component({
  selector: 'cl-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements AfterViewChecked {

  control: MatFormFieldControl<any>;

  @ContentChildren(CustomControl, {descendants: true})
  set _customControls(controls: QueryList<CustomControl>) {
    if (!controls || !controls.first) {
      return;
    }
    this.control = controls.first._internalControl;
    this.matFormField._control = this.control;
  }

  @ContentChild(MatFormFieldControl, {static: false})
  set _control(control) {
    if (!control) {
      return
    }
    this.control = control;
    this.matFormField._control = this.control;
  }

  @ViewChild(MatFormField, {static: true})
  matFormField: MatFormField;

  @Input()
  label: string;

  @Input()
  config: RowErrorsConfig = {};


  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  prepareErrors(errors: ValidationErrors): string[] {
    if (!errors) {
      return [];
    }
    return this.transformToErrors(errors);
  }

  private transformToErrors(errors: ValidationErrors): string[] {
    const buildInErrors = this.collectBuildInErrors(errors);
    const customErrors = this.collectCustomErrors(errors);
    return [
      ...buildInErrors,
      ...customErrors,
    ];
  }

  private collectCustomErrors(errors: ValidationErrors) {
    return Object.keys(errors)
      .filter(key => !(key in Errors))
      .map(key => {
        if (key in this.config) {
          return this.config[key].customMsg;
        }
        if (key in CustomValidators) {
          return (CustomValidators[key] as CustomValidator).message(errors[key])
        }
        throw `custom validator ${key} is not propper implemented`;
      });
  }

  private collectBuildInErrors(errors: ValidationErrors) {
    return Object.keys(errors)
      .filter(error => error in Errors)
      .map(error => {
        const hasErrorInConfig = error in this.config;
        return Errors[error]({
          name: this.label,
          additionalData: errors[error],
          grammarMode: hasErrorInConfig ? this.config[error].grammarMode : undefined,
          customMsg: hasErrorInConfig ? this.config[error].customMsg : undefined,
        })
      })
  }

}
