export enum ValidStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  PENDING = "PENDING",
  DISABLED = "DISABLED",
}

export enum GrammarMode {
  FEMININE = "FEMININE",
  NEUTER = "NEUTER",
  MASCULINE = "MASCULINE"
}

export const GRAMMAR_SUFFIX = {
  [GrammarMode.FEMININE]: 'wymagana',
  [GrammarMode.NEUTER]: 'wymagane',
  [GrammarMode.MASCULINE]: 'wymagany',
};

export interface ErrorConfig {
  name: string;
  grammarMode?: GrammarMode;
  customMsg?: string;
  additionalData?: any;
}

export const Errors: { [key: string]: Function } = {
  required({name, grammarMode = GrammarMode.FEMININE, customMsg = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `${name} jest ${GRAMMAR_SUFFIX[grammarMode]}`);
  },
  minlength({name, customMsg = null, additionalData = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Minimalna liczba znaków pola ${name} to ${additionalData.requiredLength} znaków`);
  },
  maxlength({name, customMsg = null, additionalData = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Maksymalna liczba znaków pola ${name} to ${additionalData.requiredLength} znaków`);
  },
  email({customMsg = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, 'Adres e-mail jest nieprawidłowy');
  },
  min({name, customMsg = null, additionalData = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Podaj wartość pola ${name} nie mniejszą niż ${additionalData.min}`);
  },
  max({name, customMsg = null, additionalData = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Podaj wartość pola ${name} nie większą niż ${additionalData.max}`);
  },
  pattern({name, customMsg = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Format pola ${name} jest nieodpowiedni`);
  },
  //wymagane przez ngx-mask
  'Mask error'({name, customMsg = null}: ErrorConfig) {
    return getCustomOrTemplate(customMsg, `Format pola ${name} jest nieodpowiedni`);
  }
};

function getCustomOrTemplate(customMsg: string, template: string): string {
  return customMsg ? customMsg : template;
}
