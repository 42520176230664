import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CustomErrorComponent, ErrorComponent, ServerErrorComponent, TechnicalErrorComponent} from '@shared/components';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./homepage/homepage.module').then(module => module.HomepageModule),
  },
  {
    path: 'zaloguj',
    loadChildren: () => import('./login/login.module').then(module => module.LoginModule),
  },
  {
    path: 'baza-wiedzy',
    loadChildren: () => import('./knowledge-base/knowledge-base.module').then(module => module.KnowledgeBaseModule),
  },
  {
    path: 'konto-szkodowe',
    loadChildren: () => import('./claim-account/claim-account.module').then(module => module.ClaimAccountModule),
  },
  {
    path: 'strony-informacyjne',
    loadChildren: () => import('./info-page/info-page.module').then(module => module.InfoPageModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
    children: [
      {
        path: '',
        component: CustomErrorComponent,
      },
      {
        path: 'technical',
        component: TechnicalErrorComponent
      },
      {
        path: '403',
        component: ServerErrorComponent,
        data: {title: '403', description: 'Access denied for this page'}
      },
      {
        path: '404',
        component: ServerErrorComponent,
        data: {title: '404', description: 'Page is not found'}
      },
      {
        path: '500',
        component: ServerErrorComponent,
        data: {title: '500', description: 'Ups... Server down...'}
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class RootRoutingModule {
}
