import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedState} from '@shared/store';
import {GoogleTagManagerService, HistoryService, LoadingSpinnerService, ModalService,} from '@shared/services';
import {Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {LoadAppSettings, SetLayoutType} from '@shared/store/actions/shared.action';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {filter, map, take} from 'rxjs/operators';
import {Layout} from '@shared/store/shared-state';
import {LoadJsService} from "./homepage/services/load-js.service";
import {CommonSharedState, onProduction} from "@commonShared/store/common-shared-state";
import {Brand, Environment, EnvironmentType} from "@commonShared/models";
import {BrandService} from "@commonShared/services/brand.service";
import {EnvironmentService} from "@commonShared/services/environment.service";

@Component({
  selector: 'cl-root',
  template: `
    <cl-global-error-messages></cl-global-error-messages>
    <router-outlet></router-outlet>
    <cl-brand-chooser></cl-brand-chooser>
    <cl-environment-info></cl-environment-info>
    <lib-loading-spinner [spinnerVisible]="spinnerVisible"></lib-loading-spinner>
  `,
})
export class RootComponent implements OnInit, AfterViewInit {

  @Select(CommonSharedState.environment)
  environment$: Observable<Environment>;

  @Select(SharedState.googleMapsApiKey)
  googleMapsApiKey$: Observable<string>;

  spinnerVisible: boolean = false;

  constructor(private brandService: BrandService,
              private store: Store,
              private router: Router,
              private loadingSpinnerService: LoadingSpinnerService,
              private breakpointObserver: BreakpointObserver,
              private modalService: ModalService,
              private environmentService: EnvironmentService,
              private googleTagManagerService: GoogleTagManagerService,
              private historyService: HistoryService,
              private loadJsService: LoadJsService) {
    this.brandService.setAndRetrieveBrandFromUrl(window.location.href);
    const environment = this.environmentService.setAndRetrieveEnvironmentFromUrl(window.location.href);
    this.loadJsService.loadJsScripts(environment.type === EnvironmentType.PROD);
  }


  ngOnInit() {
    this.initLayoutUpdate();
    this.loadAppSettings();
    this.handleLoadingSpinnerForRouterEvents();
    this.handleLoadingSpinnerForManualHandling();
    this.handleModalsForRouterEvents();
    this.loadGoogleMapsApi();
  }

  ngAfterViewInit(): void {
    const currentBrand: Brand = this.brandService.getCurrentBrand(window.location.href);
    this.environment$.pipe(onProduction())
      .subscribe(() => this.googleTagManagerService.addGtmToDom(currentBrand.gtm))
  }

  private initLayoutUpdate() {
    this.breakpointObserver.observe([
      '(min-width: 993px)', //musi być takie samo jak w @mixin desktop-up
    ])
      .pipe(
        map((result: BreakpointState) => result.matches),
        map(isDesktop => isDesktop ? Layout.DESKTOP : Layout.MOBILE)
      )
      .subscribe(layoutType => this.store.dispatch(new SetLayoutType(layoutType)));
  }

  private loadAppSettings() {
    this.store.dispatch(new LoadAppSettings());
  }

  private handleModalsForRouterEvents() {
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.historyService.add(routerEvent.urlAfterRedirects);
      }
      if (routerEvent instanceof NavigationCancel
        || routerEvent instanceof NavigationError
        || routerEvent instanceof NavigationEnd) {
        this.modalService.closeAll();
      }
    });
  }

  private handleLoadingSpinnerForRouterEvents() {
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinnerVisible = true;
      }
      if (routerEvent instanceof NavigationCancel
        || routerEvent instanceof NavigationError
        || routerEvent instanceof NavigationEnd) {
        this.spinnerVisible = false;
      }
      if (routerEvent instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.googleTagManagerService.pushTag({
          event: 'page',
          pageName: routerEvent.url
        })
      }
    });
  }

  private handleLoadingSpinnerForManualHandling() {
    this.loadingSpinnerService.loadingSpinnerVisible$.subscribe(visible => {
      this.spinnerVisible = visible;
    });
  }

  private loadGoogleMapsApi() {
    this.googleMapsApiKey$
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe({
        next: googleMapsApiKey => this.attachMapsScriptWithKey(googleMapsApiKey)
      });
  }

  private attachMapsScriptWithKey(googleMapsApiKey: string) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
    script.async = true;
    document.head.appendChild(script);
  }
}
