import {Injectable} from '@angular/core';

export const SessionStorageKeys = {
  ACCOUNT: {
    SELECTED_TAB: 'KEYS_ACCOUNT_SELECTED_TAB',
    SELECTED_CONTEXT: 'KEYS_ACCOUNT_SELECTED_CONTEXT'
  }
};

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  put(key, value: any) {
    sessionStorage.setItem(key, value);
  }

  get(key){
    return sessionStorage.getItem(key)
  }

  getAsync(key): Promise<any> {
    return new Promise<any>(function (resolve) {
      resolve(sessionStorage.getItem(key));
    });
  }

  remove(key) {
    sessionStorage.removeItem(key);
  }

}
