import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  private loadingSpinnerVisible: Subject<boolean> = new Subject<boolean>();
  loadingSpinnerVisible$: Observable<boolean> = this.loadingSpinnerVisible.asObservable();


  showLoadingSpinner(): Observable<boolean> {
    this.loadingSpinnerVisible.next(true);
    return this.loadingSpinnerVisible$;
  }


  hideLoadingSpinner(): Observable<boolean> {
    this.loadingSpinnerVisible.next(false);
    return this.loadingSpinnerVisible$;
  }
}
