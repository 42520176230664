<footer class="page-footer">
  <div class="container">
    <ul class="page-footer__menu">
      <li class="page-footer__menu__item">
        <a routerLink="/baza-wiedzy/polityka-prywatnosci" class="page-footer__menu__item__anchor" title="Polityka prywatności">Polityka prywatności</a>
      </li>
      <li class="page-footer__menu__item">
        <a routerLink="/baza-wiedzy/polityka-prywatnosci" class="page-footer__menu__item__anchor" title="Polityka cookies">Polityka cookies</a>
      </li>
      <li class="page-footer__menu__item">
        <a *ngIf="isBrandGenerali$ | async; else proamaTermsLink" class="page-footer__menu__item__anchor" title="Regulamin serwisu"
           href="https://www.generali.pl/regulaminy" target="_blank" rel="noopener">Regulamin serwisu</a>
        <ng-template #proamaTermsLink>
          <a class="page-footer__menu__item__anchor" title="Regulamin serwisu" href="https://www.proama.pl/regulaminy/"
             target="_blank" rel="noopener">Regulamin serwisu</a>
        </ng-template>
      </li>
    </ul>
  </div>
</footer>
