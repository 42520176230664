import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {Store} from '@ngxs/store';
import {SetBrand} from '@shared/store';
import {Brand, BrandName, brands} from "@commonShared/models";

@Injectable({
  providedIn: "root"
})
export class BrandService {

  private readonly defaultBrand: string = BrandName.GENERALI.toLowerCase();
  private renderer: Renderer2;
  private availableOptions: string[] = [
    BrandName.PROAMA.toLowerCase(),
    BrandName.GENERALI.toLowerCase(),
    'common'
  ];

  private readonly brandUrlParts = {
    [BrandName.GENERALI.toLowerCase()]: [BrandName.GENERALI.toLowerCase()],
    [BrandName.PROAMA.toLowerCase()]: [BrandName.PROAMA.toLowerCase(), 'groupama'],
  }

  constructor(rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document,
              private titleService: Title,
              private store: Store) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setBrand(brand: Brand) {
    const body: any = this.document.body;
    this.removeKnownClasses(body);
    this.addBrandToBody(body, brand);
    this.titleService.setTitle(brand.naturalName);
    this.store.dispatch(new SetBrand(brand.name));
  }

  setAndRetrieveBrandFromUrl(url: string): Brand {
    const brand: Brand = this.getCurrentBrand(url);
    this.setBrand(brand);
    return brand;
  }

  private removeKnownClasses(body) {
    this.availableOptions.forEach(option => this.renderer.removeClass(body, `brand-${option}`));
  }

  private addBrandToBody(body, brand) {
    this.renderer.addClass(body, `brand-${brand.name ? brand.name.toLowerCase() : brand}`);
  }

  public getCurrentBrand(url: string): Brand {
    let currentBrand = this.defaultBrand;
    Object.keys(BrandName)
      .map(brand => brand.toLowerCase())
      .forEach(brand => {
        this.brandUrlParts[brand].forEach(brandPart => {
          if (this.isBrand(url, brandPart)) {
            currentBrand = brand;
          }
        })
      })
    return brands[BrandName[currentBrand.toUpperCase()]];
  }

  private isBrand(url: string, brandName: string): boolean {
    return url.indexOf(brandName) > -1;
  }

}
