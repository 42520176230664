import {ValidationErrors} from '@angular/forms';
import {bankAccountValidator, CustomValidator} from '@shared/utils';

/**
 * Założenie jest takie by funkcja walidatora zwracała obiekt z błędem o kluczu identycznym jak nazwa eksportowany obiektu do, którego funkcja jest podpięta
 */

export const CustomValidators: {[key: string]: CustomValidator} = {
  bankAccountError: {
    message: (error: ValidationErrors) => 'Numer konta bankowego jest niepoprawny',
    validator: bankAccountValidator,
  }
}
