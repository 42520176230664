export function validateBankAccount(accountNumber) {
  accountNumber = accountNumber.replace(/[^0-9]+/g, '');
  const weights = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57];

  if (accountNumber.length === 26) {
    accountNumber = accountNumber + "2521";
    accountNumber = accountNumber.substr(2) + accountNumber.substr(0, 2);
    let checkSum = 0;
    for (let i = 0; i < 30; i++) {
      checkSum += accountNumber[29 - i] * weights[i];
    }
    return checkSum % 97 === 1 && "222233334444555566667777252111" !== accountNumber;
  } else {
    return false;
  }
}

//zmiana w tym miejscu musi również uaktualniać: ValidationConstants.java
export const partAddressFieldsBlacklist = ["danych", "brak", "n/a", "n / a", "n/ a", "n /a", "nie ma", "niema"];

//zmiana w tym miejscu musi również uaktualniać: ValidationConstants.java
export const wholeAddressFieldsBlacklist = ["na", "00-000"];

export const regex = {
  zipcodeRegex: /^[0-9]{2}-[0-9]{3}$/i,
  wordsDashedRegex: /^[a-zA-ZęóąćśłżźńĘÓĄĆŚŁŻŹŃ]+( ?-? ?[a-zA-ZęóąćśłżźńĘÓĄĆŚŁŻŹŃ])*$/i,
  streetRegex: /^([0-9a-zA-ZęóąćśłżźńĘÓĄĆŚŁŻŹŃ. ])+([0-9a-zA-ZęóąćśłżźńĘÓĄĆŚŁŻŹŃ -])*([0-9a-zA-ZęóąćśłżźńĘÓĄĆŚŁŻŹŃ]){1}$/i,
  buildingNumberRegex: /^[1-9][0-9]{0,3}(([a-zA-Z/]{0,5})([0-9]{1,4})?)*$/i,
};

