import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {ErrorHandlerService} from "@shared/services/error-handler.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private errorHandlerService: ErrorHandlerService,
              private router: Router) {
  }

  intercept = (req: HttpRequest<any>, next: HttpHandler) => {
    const xhr = req.clone({
      url: req.url.indexOf('http') === 0 ? req.url : `/claimonweb/${req.url}`,
      headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
    });
    return next.handle(xhr).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.errorHandlerService.isTechnicalError(error)) {
          this.errorHandlerService.getTechnicalErrorData(error)
            .then(messages => this.router.navigateByUrl("/error/technical", {
              state: {
                messages
              }
            }));
        }
        return throwError(error);
      }));
  };

  private omitErrorAdding(error: HttpErrorResponse) {
    const fromAuthGuard = () => error.url.endsWith("/user/check");
    return fromAuthGuard();
  }
}
