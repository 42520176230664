<div class="trusted-device__item">
  <form
    [formGroup]="deviceForm" (ngSubmit)="changeName()"
    class="trusted-device__name"
    [class.trusted-device__name--computer]="isDeviceComputer"
    [class.trusted-device__name--mobile]="!isDeviceComputer">

    <span *ngIf="!isEditNameMode" class="trusted-device__name__value">
      <ng-container *ngIf="device.userDeviceName; else placeholderLabel">{{ device.userDeviceName }}</ng-container>
      <ng-template #placeholderLabel>
        <ng-container *ngIf="isDeviceComputer;else mobileLabel">Komputer</ng-container>
        <ng-template #mobileLabel>Telefon lub tablet</ng-template>
      </ng-template>
      <button (click)="editName()" type="button" mat-button class="trusted-device__name__value__button" title="Edytuj">
        <mat-icon>mode_edit</mat-icon>
      </button>
    </span>

    <cl-editable-field
      *ngIf="isEditNameMode"
      (canceled)="cancelEditName()"
      (accepted)="changeName()"
      formControlName="name">
    </cl-editable-field>

  </form>
  <div class="trusted-device__browser">{{ device.deviceName }}</div>
  <div class="trusted-device__location">
    <div class="trusted-device__location__row">
      <span class="trusted-device__location__label">Rejestracja: </span>
      <span
        class="trusted-device__location__value">{{ device | userDeviceDateOrder | date : 'HH:mm:ss dd/MM/yyyy' }}</span>
    </div>
    <div *ngIf="device.ipAddress" class="trusted-device__location__row">
      <span class="trusted-device__location__label">z IP: </span>
      <span class="trusted-device__location__value">{{ device.ipAddress }}</span>
    </div>
  </div>
  <div class="trusted-device__action">
    <button
      *ngIf="!shouldDeviceRemovalVisible && !device.current"
      (click)="showRemoveConfirm()"
      type="button"
      class="trusted-device__action__button--remove"
      mat-ripple>
      Usuń
    </button>
    <button
      *ngIf="shouldDeviceRemovalVisible"
      (click)="hideRemoveConfirm()"
      type="button"
      class="trusted-device__action__button--cancel"
      mat-ripple>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="shouldDeviceRemovalVisible" class="trusted-device__confirm">
    <div class="trusted-device__confirm__message">Prosimy potwierdź usunięcie urządzenia z zaufanych</div>
    <button
      (click)="removeDevice()"
      type="button"
      class="trusted-device__confirm__action"
      mat-ripple>
      Potwierdzam usunięcie
    </button>
  </div>
</div>
