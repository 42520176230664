import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-loading-spinner',
  template: `
    <div *ngIf="spinnerVisible" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>`,
  styleUrls: ['./loading-spinner.scss']
})
export class LoadingSpinner {
    @Input()
    spinnerVisible: boolean = false;
}
