import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'cl-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableFieldComponent),
      multi: true,
    },
  ],
})
export class EditableFieldComponent implements ControlValueAccessor {

  @Input()
  asCase: boolean = false;

  @Input()
  label: string;

  @Output()
  canceled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  accepted: EventEmitter<void> = new EventEmitter<void>();

  value: string;

  onChange(obj: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched() {
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.onChange(this.value);
    this.onTouched();
  }

  accept() {
    this.accepted.emit();
  }

  cancel() {
    this.canceled.emit();
  }
}
