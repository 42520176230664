import {Injectable} from '@angular/core';
import {Settings} from '@shared/store/shared-state';

@Injectable({
  providedIn: 'root'
})
export class SyncSettingsService {

  private settings: Settings = {
    agrocasco: null,
    googleReCaptchaSiteKey: null,
    googleMapsApiKey: null,
    serverNode: null,
    autoclaimsUrl: null,
    documents: {},
    brand: null,
    enabledFeatures: [],
    concordiaClaims: null,
    concordiaClaimRegisterEmail: null,
  };

  get(): Settings {
    return this.settings;
  }

  update(settings: Settings) {
    this.settings = settings;
  }

}
