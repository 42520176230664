import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

const DEFAULT_ERROR_TITLE: string = "Błąd";

@Component({
  selector: 'cl-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['../layout/error.component.scss']
})
export class CustomErrorComponent implements OnInit {
  title: string = DEFAULT_ERROR_TITLE;
  message: string;

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { title: string, message: string };

    if (state.title) {
      this.title = state.title;
    }
    this.message = state.message;
  }

  ngOnInit() {
  }
}

