import {Component, Input} from '@angular/core';

@Component({
  selector: 'cl-text-page-layout',
  templateUrl: './text-page-layout.component.html',
  styleUrls: ['./text-page-layout.component.scss']
})
export class TextPageLayoutComponent {

  @Input()
  fromError: boolean = false;

}
