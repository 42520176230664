<div class="modal__wrapper">
  <p class="modal__question">{{ message }}</p>
  <div class="modal__actions">
    <cl-confirm-button
      [style]="ConfirmButtonStyle.FILL"
      class="modal__confirm"
      [showArrow]="false"
      (onConfirm)="confirm()">
      {{ yes }}
    </cl-confirm-button>
    <cl-confirm-button
      [style]="ConfirmButtonStyle.OUTLINE"
      class="modal__cancel"
      [showArrow]="false"
      (onConfirm)="cancel()">
      {{ no }}
    </cl-confirm-button>
  </div>
</div>

