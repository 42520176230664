import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CONTEXT_PATH_API} from '@shared/config';
import {timer} from 'rxjs';
import {
  DispositionGroupUploadedFileContext,
  DispositionUploadedFileContext,
  GroupUploadedFileContext,
  SingleUploadedFileContext,
  UploadedFileContext,
  UploadedFileType
} from './../documents.model';

@Component({
  selector: '[cl-file-uploaded-view-dialog-content]',
  templateUrl: './file-uploaded-view-dialog-content.component.html',
  styleUrls: ['./../file-preview-dialog/file-preview-dialog.scss']
})
export class FileUploadedViewDialogContentComponent implements OnInit {

  imageSuccessfulLoaded: boolean = false;
  pdfSuccessfulLoaded: boolean = false;
  pdfTryingStarted: boolean = false;
  duringLoading: boolean = true;
  showErrorMsg: boolean = false;

  path: string = CONTEXT_PATH_API;

  constructor(public dialogDef: MatDialogRef<FileUploadedViewDialogContentComponent>,
              @Inject(MAT_DIALOG_DATA) public file: UploadedFileContext) {
  }

  ngOnInit() {
    timer(60000).subscribe(() => this.showError());
  }

  showError() {
    if (this.imageSuccessfulLoaded || this.pdfSuccessfulLoaded) {
      return;
    }
    this.duringLoading = false;
    this.imageSuccessfulLoaded = false;
    this.pdfSuccessfulLoaded = false;
    this.pdfTryingStarted = false;
    this.showErrorMsg = true;
  }

  showImage() {
    this.duringLoading = false;
    this.imageSuccessfulLoaded = true;
    this.pdfTryingStarted = false;
  }

  tryLoadPdf() {
    this.imageSuccessfulLoaded = false;
    this.pdfTryingStarted = true;
    this.duringLoading = true;
  }

  showPdf() {
    this.duringLoading = false;
    this.pdfSuccessfulLoaded = true;
  }

  getSrc() {
    let src = `${CONTEXT_PATH_API}/download-documents/`;
    if (this.file.subCaseId) {
      src += "subcase/";
    } else {
      src += "case/";
    }
    switch (this.file.type) {
      case UploadedFileType.DISPOSITION:
        src += "disposition/";
        break
      case UploadedFileType.DISPOSITION_GROUP:
        src += "disposition-group/"
        break;
      case UploadedFileType.GROUP:
        src += "group/";
        break
      case UploadedFileType.SINGLE:
        src += "single/";
        break;
    }
    src += `${this.file.caseId}/`;
    if (this.file.subCaseId) {
      src += `${this.file.subCaseId}/`;
    }
    switch (this.file.type) {
      case UploadedFileType.DISPOSITION:
        return src + (this.file as DispositionUploadedFileContext).documentId;
      case UploadedFileType.DISPOSITION_GROUP:
        const dispositionGroupFile = this.file as DispositionGroupUploadedFileContext;
        return src + `${dispositionGroupFile.mergeId}/${dispositionGroupFile.documentKey}`;
      case UploadedFileType.GROUP:
        const groupFile = this.file as GroupUploadedFileContext;
        return src + `${groupFile.mergeId}/${groupFile.documentKey}`;
      case UploadedFileType.SINGLE:
        const singleFile = this.file as SingleUploadedFileContext;
        return src + `${singleFile.matchId}/${singleFile.documentId}`;
    }
  }
}
