import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'cl-technical-error',
  templateUrl: './technical-error.component.html',
  styleUrls: ['../layout/error.component.scss']
})
export class TechnicalErrorComponent {

  errorMessages: string[];

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state && navigation.extras.state.messages) {
      this.errorMessages = this.router.getCurrentNavigation().extras.state.messages as string[];
    } else {
      this.router.navigateByUrl("/");
    }
  }

}

