import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {SharedState} from '@shared/store/shared-state';

@Component({
  selector: 'cl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Select(SharedState.isBrandGenerali)
  isBrandGenerali$: Observable<boolean>;

}
