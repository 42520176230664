export enum EnvironmentType {
  DEV = 'DEV',
  TEST = 'TEST',
  PROD = 'PROD',
}

export interface Environment {
  type: EnvironmentType;
  showUnderConstructionSign: boolean;
}
