import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "@shared/services/error-handler.service";

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorService {

  private errors: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  errors$: Observable<string[]> = this.errors.asObservable();

  handleErrors(errorResponse: HttpErrorResponse) {
    this.errors.next(ErrorHandlerService.getValidationErrors(errorResponse));
  }

  appendError(error: string) {
    this.errors.next([...this.errors.getValue(), error]);
  }

  addError(error: string) {
    this.errors.next([error]);
  }

  clear() {
    this.errors.next([])
  }

}
