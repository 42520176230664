<cl-popup title="Twoje zaufane urządzenia" [dialog]="dialogDef">
  <div *ngIf="duringLoading" class="trusted-device__loading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="devices$ | async as devices" class="trusted-device__list">
    <cl-user-device
      *ngFor="let device of devices"
      [device]="device"
      (removed)="removeDevice(device)"
      (nameChanged)="changeDeviceName(device, $event)">
    </cl-user-device>
  </div>
</cl-popup>
