import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CONTEXT_PATH_API} from '@shared/config';
import {DocumentUploadedFile} from '@shared/features';

interface Context {
  file: DocumentUploadedFile,
  subCaseId: string;
  caseId: string;
}

@Component({
  selector: '[cl-file-upload-view-dialog-content]',
  templateUrl: './file-upload-view-dialog-content.component.html',
  styleUrls: ['./../file-preview-dialog/file-preview-dialog.scss']
})
export class FileUploadViewDialogContentComponent {

  path: string = CONTEXT_PATH_API;

  constructor(public dialogDef: MatDialogRef<FileUploadViewDialogContentComponent>,
              @Inject(MAT_DIALOG_DATA) public context: Context) {
  }

  isPdf(): boolean {
    return this.context.file.fileSaved.contentType.indexOf('pdf') > -1;
  }

  getSrc() {
    const part = this.context.subCaseId ? 'sub-case' : 'case';
    return `${CONTEXT_PATH_API}/document/${part}/${this.context.file.fileSaved.id}`;
  }
}
