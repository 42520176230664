import {Action, ActionType, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {LoadAppSettings, SetBrand, SetLayoutType} from '@shared/store/actions/shared.action';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {SyncSettingsService} from '@shared/services/sync-settings.service';
import {Injectable} from '@angular/core';
import {BrandName} from "@commonShared/models";

export enum Layout {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export enum EnabledFeature {
  ONLINE_CALENDAR = 'ONLINE_CALENDAR',
}

export interface DocumentType {
  key: string;
  name: string;
}

export interface Documents {
  [key: string]: DocumentType;
}

export enum ConcordiaClaimType {
  CROPS = 'CROPS',
  VEHICLES = 'VEHICLES',
}

export interface ConcordiaClaims {
  [ConcordiaClaimType.CROPS]?: string;
  [ConcordiaClaimType.VEHICLES]?: string;
}

export interface Settings {
  agrocasco: boolean;
  serverNode: string;
  googleReCaptchaSiteKey: string;
  googleMapsApiKey: string;
  autoclaimsUrl: string;
  documents: Documents;
  brand: string;
  enabledFeatures: EnabledFeature[];
  concordiaClaims: ConcordiaClaims;
  concordiaClaimRegisterEmail: string;
}

interface SharedModel {
  agrocasco: boolean;
  brand: string;
  serverNode: string;
  googleReCaptchaSiteKey: string;
  googleMapsApiKey: string;
  autoclaimsUrl: string;
  settingsLoaded: boolean;
  layoutType: Layout;
  documents: Documents;
  enabledFeatures: EnabledFeature[];
  concordiaClaims: ConcordiaClaims;
  concordiaClaimRegisterEmail: string;
}

@State<SharedModel>({
  name: 'shared',
  defaults: {
    agrocasco: null,
    brand: null,
    serverNode: null,
    googleReCaptchaSiteKey: null,
    googleMapsApiKey: null,
    autoclaimsUrl: null,
    settingsLoaded: false,
    layoutType: Layout.MOBILE,
    documents: {},
    enabledFeatures: [],
    concordiaClaims: null,
    concordiaClaimRegisterEmail: null,
  }
})
@Injectable()
export class SharedState {

  constructor(private http: HttpClient,
              private syncSettingsService: SyncSettingsService) {
  }

  @Selector()
  static featureEnabled(feature: EnabledFeature) {
    return createSelector([SharedState], (state: { shared: SharedModel }) => state.shared.enabledFeatures.includes(feature))
  }

  @Selector()
  static brand(state: SharedModel) {
    return state.brand;
  }

  @Selector()
  static brandName(state: SharedModel) {
    return state.brand.toLocaleLowerCase();
  }

  @Selector()
  static isBrandGenerali(state: SharedModel) {
    return state.brand === BrandName.GENERALI;
  }

  @Selector()
  static isBrandProama(state: SharedModel) {
    return state.brand === BrandName.PROAMA;
  }

  @Selector()
  static node(state: SharedModel) {
    return state.serverNode;
  }

  @Selector()
  static googleReCaptchaSiteKey(state: SharedModel) {
    return state.googleReCaptchaSiteKey;
  }

  @Selector()
  static googleMapsApiKey(state: SharedModel) {
    return state.googleMapsApiKey;
  }

  @Selector()
  static autoclaimsUrl(state: SharedModel) {
    return state.autoclaimsUrl;
  }

  @Selector()
  static settingsLoaded(state: SharedModel) {
    return state.settingsLoaded;
  }

  @Selector()
  static layout(state: SharedModel) {
    return state.layoutType;
  }

  @Selector()
  static documents(state: SharedModel) {
    return state.documents;
  }

  @Selector()
  static concordiaClaimsCrops(state: SharedModel) {
    return state.concordiaClaims[ConcordiaClaimType.CROPS];
  }

  @Selector()
  static concordiaClaimsVehicles(state: SharedModel) {
    return state.concordiaClaims[ConcordiaClaimType.VEHICLES];
  }

  @Selector()
  static agrocasco(state: SharedModel): boolean {
    return state.agrocasco;
  }

  @Selector()
  static concordiaClaimsRegisterAddress(state: SharedModel) {
    return state.concordiaClaimRegisterEmail;
  }

  @Action(<ActionType> LoadAppSettings)
  loadSettings(ctx: StateContext<SharedModel>) {
    this.http.get('frontend/settings').pipe(take(1)).toPromise().then((settings: Settings) => {
      ctx.patchState({
        agrocasco: settings.agrocasco,
        serverNode: settings.serverNode,
        googleReCaptchaSiteKey: settings.googleReCaptchaSiteKey,
        googleMapsApiKey: settings.googleMapsApiKey,
        autoclaimsUrl: settings.autoclaimsUrl,
        documents: settings.documents,
        brand: !!settings.brand ? settings.brand : ctx.getState().brand,
        enabledFeatures: settings.enabledFeatures,
        concordiaClaims: settings.concordiaClaims,
        concordiaClaimRegisterEmail: settings.concordiaClaimRegisterEmail,
        settingsLoaded: true,
      });
      this.syncSettingsService.update(settings);
    });
  }

  @Action(SetBrand)
  loadCurrentUser(ctx: StateContext<SharedModel>, action: SetBrand) {
    ctx.patchState({
      brand: action.brand,
    })
  }

  @Action(SetLayoutType)
  setLayoutType(ctx: StateContext<SharedModel>, action: SetLayoutType) {
    ctx.patchState({
      layoutType: action.layoutType,
    })
  }
}
