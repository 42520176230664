import {Directive, HostListener, Input, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {HistoryService} from '@shared/services';

@Directive({
  selector: '[clBack]'
})
export class BackDirective {

  @Input('clBackFromError')
  fromError: boolean = false;

  constructor(private historyService: HistoryService,
              private router: Router,
              private zone: NgZone) {
  }

  @HostListener('click')
  onClick() {
    if (this.fromError) {
      this.historyService.clear();
      // angular sugeruje w console.warn by użyć NgZone
      this.zone.run(() => this.router.navigateByUrl("/"))
      return
    }
    this.router.navigateByUrl(this.historyService.popLatest());
  }
}
