import {Component, OnInit} from '@angular/core';
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";

@Component({
  selector: 'cl-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['../layout/error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  title$: Observable<string>;
  message$: Observable<string>;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.title$ = this.route.data.pipe(
      map(data => data.title)
    );
    this.message$ = this.route.data.pipe(
      map(data => data.description)
    );
  }
}
