import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {CommonSharedState} from "@commonShared/store/common-shared-state";
import {Environment} from "@commonShared/models";

@Component({
  selector: 'cl-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.scss']
})
export class EnvironmentInfo {
  @Select(CommonSharedState.environment)
  environment$: Observable<Environment>;

  constructor() {
  }
}
