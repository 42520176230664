import {ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {workingDaysFilter} from '@shared/utils';
import {Moment} from 'moment';
import {MatFormFieldControl} from '@angular/material/form-field';
import {CustomControl} from "@shared/form/custom.control";

@Component({
  selector: 'cl-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: CustomControl,
      useExisting: forwardRef(() => DatepickerComponent),
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {

  @ViewChild(MatFormFieldControl, {static: true})
  _internalControl: MatFormFieldControl<any> = null

  @Input()
  minDate: Moment;

  @Input()
  maxDate: Moment;

  @Input()
  disabled: boolean = false;

  required: boolean = false;

  dates;

  @Input('required')
  set _required(required) {
    if (!!required) {
      this.form.get('value').setValidators(Validators.required)
    } else {
      this.form.get('value').clearValidators();
    }
  }

  @Input()
  set workingDaysOnly(value) {
    if (!!value) {
      this.dateFilter = workingDaysFilter;
    } else {
      this.dateFilter = this.allDatesPassFilter;
    }
  }

  @Input()
  set filterDates(filterDates) {
    this.dates = filterDates;
    this.dateFilter = this.passedDatesFilter;
  }

  private allDatesPassFilter = (date: Moment) => true;

  private passedDatesFilter = (date: Moment | null): boolean => {
    return date ? this.dates[date.toISOString().split('T')[0]] : false;
  }

  dateFilter = this.allDatesPassFilter;

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef) {
    //z uwagi na to, że do this.form dostaje się w @Input to musi inicjalizacja być w konstruktorze a nie w ngOnInit
    this.form = this.fb.group({
      value: [null, []]
    })
  }

  ngOnInit() {
    //wywoływane z uwagi na zmianę formularza przez @Input
    this.changeDetectorRef.detectChanges();
  }

  onChange(obj: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched() {
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.form.get('value').setValue(obj);
    this.onChange(this.form.get('value').value);
    this.onTouched();
  }
}
