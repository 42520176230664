import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserDevice, UserDevices} from './user-devices.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HateoasResponse} from "@commonShared/models";

@Injectable({
  providedIn: 'root',
})
export class UserDevicesService {

  private readonly apiPrefix = 'user/api/claim-account/user-device';

  constructor(private http: HttpClient) {
  }

  loadDevices(): Observable<UserDevice[]> {
    return this.http.get<HateoasResponse<UserDevices>>(this.apiPrefix).pipe(
      map(userDevices => userDevices._embedded.userDeviceDtoes)
    )
  }

  deleteUserDevice(userDevice: UserDevice): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userAgent: userDevice.userAgent,
        cookieId: userDevice.cookieId
      },
    };
    return this.http.delete(this.apiPrefix + '/', options);
  }

  changeUserDeviceName(userDevice: UserDevice, name: string): Observable<any> {
    return this.http.put(`${this.apiPrefix}/${userDevice.cookieId}`, {name})
  }
}
