import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, mapTo, shareReplay, tap} from 'rxjs/operators';
import {Base64} from 'js-base64';

export interface ChangePasswordResponse {
  error: string;
}

export interface UserDeviceConfirmationResponse {
  deviceConfirmed: boolean;
}

export interface Credentials {
  username: string;
  password: string;
  identifier: string;
  captcha?: string;
}

export interface LoginStatus {
  loginSuccessful: boolean;
}

export enum IdentifierType {
  PESEL = 'PESEL',
  REGON = 'REGON',
  BIRTH_DATE = 'BIRTH_DATE',
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private authenticated = false;

  constructor(private http: HttpClient) {
  }

  getIdentifierType(credentials: Credentials): Observable<IdentifierType> {
    const basicString = 'Basic ' + Base64.encode(credentials.username + ':' + credentials.password);
    const headers = new HttpHeaders({IdentifierAuthorization: basicString});
    return this.http.post<IdentifierType>('unauth-user/identifier-type', null, {headers: headers});
  }

  login(credentials: Credentials) {
    const identifierHash = Base64.encode(credentials.identifier);
    const basicString = 'Basic ' + Base64.encode(credentials.username + ':' + credentials.password + ':' + identifierHash);
    const headers = new HttpHeaders({
      authorization: basicString,
      captcha: credentials.captcha || ''
    });

    return this.http
      .get<any>('user/login', {headers: headers})
      .pipe(tap(response => {
        if (response['username']) {
          this.authenticated = true;
          return response;
        }
      }));
  }

  isLoggedIn() {
    return this.http.get('user/check')
      .pipe(
        mapTo(true),
        catchError(() => of(false)),
        shareReplay(1)
      );
  }

  private logoutUser() {
    this.authenticated = false;
  }

  logout() {
    return this.http.get<Observable<any>>('user/logout')
      .pipe(
        tap(() => this.logoutUser()),
        catchError(error => {
          console.error('Error druring logout', error);
          this.logoutUser();
          return of(null)
        })
      );
  }

  setPassword(password: string) {
    const params = new HttpParams().append('password', password);
    return this.http
      .post<ChangePasswordResponse>('user/api/set-new-password', params);
  }

  resetPassword(login: string, phoneNumer: string) {
    const params = new HttpParams().append('login', login).append('phoneNumber', phoneNumer);
    return this.http
      .post<Observable<any>>('unauth-user/reset-password', params);
  }


  setDeviceConfirmation(password: string) {
    const params = new HttpParams().append('password', password);
    return this.http
      .post<UserDeviceConfirmationResponse>('user/api/set-device-confirmation', params);
  }
}
