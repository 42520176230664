<header class="page-header">
  <figure class="page-header__logo" *ngIf="brand$ | async as brand">
    <a routerLink="/">
      <ng-container [ngSwitch]="logoMode$ | async">
        <img
          *ngSwitchCase="LogoMode.GENERALI_AGRO"
          src="assets/icons/generali/logo-agro.svg"
          alt="Generali Agro"
          class="page-header__logo__image page-header__logo__image--agro"
          >
        <img
          *ngSwitchDefault
          [src]="'assets/icons/' + brand + '/logo.svg'"
          [alt]="brands[brand.toUpperCase()].naturalName"
          class="page-header__logo__image">
      </ng-container>
    </a>
  </figure>
</header>
