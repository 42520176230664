import {animate, style, transition, trigger} from '@angular/animations';

const materialAnimationFunction = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)'

export const slideLeftAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({transform: 'translateX(-100%)'}),
    animate(materialAnimationFunction, style({transform: 'translateX(0%)'}))
  ]),
  transition(':leave', [
    animate(materialAnimationFunction, style({transform: 'translateX(-100%)'}))
  ])
])
