import {Component, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {SharedState} from '@shared/store/shared-state';
import {brands as availableBrands} from '@commonShared/models';
import {LogoMode, LogoModeService} from "@shared/services/logo-mode.service";

@Component({
  selector: 'cl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  brands = availableBrands;
  LogoMode = LogoMode;

  @Select(SharedState.brandName)
  brand$: Observable<string>;

  logoMode$: Observable<LogoMode>

  constructor(private logoModeService: LogoModeService) {
  }

  ngOnInit() {
    this.logoMode$ = this.logoModeService.logoMode$;
  }

}
