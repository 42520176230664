import {Component, isDevMode, OnInit} from '@angular/core';
import {BrandService} from "@commonShared/services/brand.service";
import {brands} from "@commonShared/models";


@Component({
  selector: 'cl-brand-chooser',
  templateUrl: './brand-chooser.component.html',
  styleUrls: ['./brand-chooser.component.scss']
})
export class BrandChooserComponent implements OnInit {

  showHelpers: boolean = false;

  constructor(private brandService: BrandService) {
  }

  ngOnInit() {
    this.showHelpers = isDevMode();
  }

  changeBrand(brand: string) {
    this.brandService.setBrand(brands[brand.toUpperCase()] || brand);
  }

}
