<button mat-button
        type="button"
        (click)="confirmed()"
        class="confirm-button"
        [class.confirm-button--inactive]="inactive"
        [class.confirm-button--fill]="ConfirmButtonStyle.FILL === style"
        [class.confirm-button--outline]="ConfirmButtonStyle.OUTLINE === style"
        [disabled]="!valid || disabled">
  <span class="confirm-button__label">
    <ng-content></ng-content>
    <i *ngIf="showArrow" class="confirm-button__icon"></i>
    <span *ngIf="duringLoading" class="confirm-button__loading">
      <mat-spinner [strokeWidth]="5"></mat-spinner>
    </span>
  </span>
</button>
