<cl-popup [dialog]="dialogDef" [title]="file.name">

  <div class="file-upload-view-dialog-panel">

    <div *ngIf="showErrorMsg" class="file-upload-view-dialog-panel__error">Nie udało się pokazać dokumentu</div>

    <div *ngIf="duringLoading" class="file-upload-view-dialog-panel__loading">
      <mat-spinner></mat-spinner>
    </div>

    <div [hidden]="!imageSuccessfulLoaded" class="file-upload-view-dialog-panel__image">
      <img [src]="getSrc()" (error)="tryLoadPdf()" (load)="showImage()" [alt]="file.name">
    </div>

    <div *ngIf="pdfTryingStarted" class="file-upload-view-dialog-panel__pdf" [class.file-upload-view-dialog-panel__pdf--loaded]="pdfSuccessfulLoaded">
      <ng2-pdfjs-viewer [pdfSrc]="getSrc()"
                        [externalWindow]="false"
                        [downloadFileName]="file.name"
                        [openFile]="false"
                        [viewBookmark]="false"
                        (onDocumentLoad)="showPdf()"
                        viewerId="pdfjsViewerId"
                        [download]="false"
                        zoom="page-width">
      </ng2-pdfjs-viewer>
    </div>

  </div>

</cl-popup>
