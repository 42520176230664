import {Layout} from '@shared/store/shared-state';

export const SET_BRAND = '[Brand] Set brand';
export const LOAD_APP_SETTINGS = '[APP] Load app settings';
export const SET_LAYOUT_TYPE = '[Layout] Set layout type';

export class SetBrand {
  static readonly type = SET_BRAND;

  constructor(public brand: string) {
  }
}

export class LoadAppSettings {
  static readonly type = LOAD_APP_SETTINGS;
}

export class SetLayoutType {
  static readonly type = SET_LAYOUT_TYPE;

  constructor(public layoutType: Layout) {
  }
}
