import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfirmModalComponent} from "@shared/modal";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  private openedModals: Map<Function, MatDialogRef<any>> = new Map<Function, MatDialogRef<any>>();

  constructor(private dialog: MatDialog, private matSnackBar: MatSnackBar) {
  }

  open(content, data: any = {}, options: MatDialogConfig = {}) {
    const ref = this.openedModals.get(content);
    if (ref) {
      this.close(ref, content);
    } else {
      const recentlyOpenedModalRef = this.dialog.open(content, {
        data: data,
        ...options
      });
      recentlyOpenedModalRef.afterClosed()
        .pipe(
          tap(() => this.close(recentlyOpenedModalRef, content)),
          take(1)
        )
        .subscribe();
      this.openedModals.set(content, recentlyOpenedModalRef);
    }
  }

  private close(ref: MatDialogRef<any>, content: Function) {
    ref.close();
    this.openedModals.delete(content);
  }

  openForResult(content, data, options: MatDialogConfig = {}): Observable<any> {
    const ref = this.openedModals.get(content);
    let dialog = null;
    if (ref) {
      dialog = ref;
    } else {
      const recentlyOpenedModalRef = this.dialog.open(content, {
        data: data,
        ...options
      });
      this.openedModals.set(content, recentlyOpenedModalRef);
      dialog = recentlyOpenedModalRef;
    }
    return dialog.afterClosed()
      .pipe(
        tap(() => this.close(dialog, content)),
        take(1));
  }

  closeAll() {
    for (const modal of this.openedModals.values()) {
      modal.close();
    }
    this.openedModals.clear();
  }

  confirm(data, options: MatDialogConfig = {}): Observable<any> {
    return this.openForResult(ConfirmModalComponent, data, options)
  }

  confirmed(message: string) {
    this.matSnackBar.open(message, 'Zamknij', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

}
