import {HttpErrorResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {take} from "rxjs/operators";
import {Observable} from "rxjs";
import {SharedState} from "@shared/store/shared-state";
import {Select} from "@ngxs/store";

const standardErrorMsg = 'Wystąpił nieoczekiwany błąd.';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  static readonly TECHNICAL_ERROR = 'TECHNICAL_ERROR';
  static readonly VALIDATION_ERROR = 'VALIDATION_ERROR';
  static readonly FLOW_ERROR = 'FLOW_ERROR';

  @Select(SharedState.node)
  private node$: Observable<string>;

  static getValidationErrors(errorResponse: HttpErrorResponse): string[] {
    if (!('error' in errorResponse)) {
      return [];
    }
    const errorData = errorResponse.error;
    if (!('errorType' in errorData)) {
      return [];
    }
    if (errorData.errorType !== ErrorHandlerService.VALIDATION_ERROR) {
      return [];
    }
    if (!('validationErrors' in errorData)) {
      return [];
    }
    return errorData.validationErrors;
  }

  isTechnicalError(errorResponse: HttpErrorResponse): boolean {
    return errorResponse.error && 'errorType' in errorResponse.error && errorResponse.error.errorType === ErrorHandlerService.TECHNICAL_ERROR;
  }

  getTechnicalErrorData(errorResponse: HttpErrorResponse): Promise<string[]> {
    return new Promise(resolve => {
      return this.parseErrors([{
        refNumber: errorResponse.error.refNumber,
        message: errorResponse.message
      }])
        .then(messages => resolve(messages));
    });
  }

  private static generateErrorOccurredDate(): string {
    return `Data wystąpienia: ${new Date()}.`;
  }

  private generateErrorOccurredServer(): Promise<string> {
    return this.node$.pipe(
      take(1)
    ).toPromise()
      .then(node => `Serwer: ${node}.`)
  }

  private parseErrors(errors: { refNumber: string; message: string; }[]): Promise<string[]> {
    return this.generateErrorOccurredServer()
      .then(nodeMsg => {
        if (Array.isArray(errors)) {
          return errors.map(errorObj => errorObj.refNumber
            ? `${standardErrorMsg} o numerze technicznym: ${errorObj.refNumber}. ${ErrorHandlerService.generateErrorOccurredDate()}`
            : errorObj.message
          );
        } else {
          return [
            `${JSON.stringify(errors)} ${ErrorHandlerService.generateErrorOccurredDate()}`
          ]
        }
      })
  }
}
