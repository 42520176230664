import {DocumentStatusDescription} from "../../../../claim-account/models/claim-account.model";

export enum Status {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
}

export enum UploadedFileType {
  SINGLE = "SINGLE",
  GROUP = "GROUP",
  DISPOSITION = "DISPOSITION",
  DISPOSITION_GROUP = "DISPOSITION_GROUP",
}

export enum DocumentSection {
  REQUIRED = 'REQUIRED',
  SUGGESTED = 'SUGGESTED',
}

export interface DocumentUploadedFile {
  fileSaved: UploadedFile;
  status: Status;
  type: string;
  matchId: string;
  groupMatchId: string;
  size: number;
}

export interface UploadedFile {
  id: string;
  name: string;
  contentType: string;
}

export interface UploadedFileContext {
  caseId: string;
  subCaseId?: string;
  type: UploadedFileType;
  name: string;
}

export interface SingleUploadedFileContext extends UploadedFileContext {
  documentId: string;
  matchId: string;
}

export interface DispositionUploadedFileContext extends UploadedFileContext {
  documentId: string;
}

export interface DispositionGroupUploadedFileContext extends UploadedFileContext {
  documentId: string;
  documentKey: string;
  mergeId: string;
}

export interface GroupUploadedFileContext extends UploadedFileContext {
  documentKey: string;
  matchId: string;
  mergeId: string;
  groupMatchId: string;
}


export interface DocumentsToDownloadContext {
  caseId: string;
  subCaseId: string;
  riskName: string;
  caseDocuments: {
    singleDocuments: SingleDocumentContext[];
    groupDocuments: GroupDocumentContext[];
    dispositionDocuments: DispositionDocumentContext[];
    dispositionGroupDocuments: GroupDocumentContext[];
  };
  subCaseDocuments: {
    singleDocuments: SingleDocumentContext[];
    groupDocuments: GroupDocumentContext[];
    dispositionDocuments: DispositionDocumentContext[];
    dispositionGroupDocuments: GroupDocumentContext[];
  };
}

export interface SingleDocumentContext {
  matchId: string;
  documentId: string;
  name: string;
}

export interface GroupDocumentContext {
  mergeId: string;
  documentKey: string;
  name: string;
}

export interface DispositionDocumentContext {
  documentId: string;
  name: string;
}

export interface DocumentType {
  type: string;
  description: string;
}

export interface SuggestedDocumentsContext {
  type: string;
  description: string;
  add_button: string;
  filesToUpload: DocumentUploadedFile[];
}

export interface DocumentListItem {
  documentId: string;
  description: string;
  filename: string;
  status: DocumentStatusDescription;
  typeName: string;
  fileType: UploadedFileType;

  documentKey: string;
  matchId: string;
  mergeId: string;
  groupMatchId: string;
}
