import {Component, Input} from '@angular/core';

@Component({
  selector: 'cl-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {

  @Input()
  dialog;

  @Input()
  title;

  close() {
    this.dialog.close();
  }

}
