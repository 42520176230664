import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Environment, EnvironmentType} from '../models';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SetEnvironment} from "@commonShared/store/actions/common-shared.action";

interface CommonSharedModel {
  environment: Environment;
}

export const onProduction = () => (source: Observable<Environment>) => source.pipe(
  filter(environment => !!environment),
  map(environment => environment.type),
  filter(type => !!type),
  map(type => type === EnvironmentType.PROD),
  distinctUntilChanged(),
  filter(isProduction => isProduction)
)

@State<CommonSharedModel>({
  name: 'commonShared',
  defaults: {
    environment: null,
  }
})
@Injectable()
export class CommonSharedState {
  @Selector()
  static environment(state: CommonSharedModel) {
    return state.environment;
  }

  @Action(SetEnvironment)
  loadEnvironment(ctx: StateContext<CommonSharedModel>, action: SetEnvironment) {
    ctx.patchState({
      environment: action.environment,
    })
  }
}
