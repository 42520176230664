import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmButtonStyle} from '@shared/components';

export interface ConfirmMessages {
  message: string;
  yes: string;
  no: string;
}

@Component({
  selector: '[cl-confirm-modal]',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  ConfirmButtonStyle = ConfirmButtonStyle;

  message: string = '';
  yes: string = 'Tak';
  no: string = 'Nie';

  constructor(public dialogDef: MatDialogRef<ConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) public confirmMessages: ConfirmMessages) {
  }

  ngOnInit() {
    this.message = this.confirmMessages?.message || "Czy na pewno?"
    this.yes = this.confirmMessages?.yes || "Tak"
    this.no = this.confirmMessages?.no || "Nie"
  }

  confirm() {
    this.dialogDef.close(true)
  }

  cancel() {
    this.dialogDef.close(false)
  }
}
