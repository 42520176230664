import {Component} from '@angular/core';

@Component({
  selector: 'cl-styled-content',
  templateUrl: './styled-content.component.html',
  styleUrls: ['./styled-content.component.scss']
})
export class StyledContentComponent {

}
