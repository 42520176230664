import {Component, EventEmitter, Input, Output} from '@angular/core';

export enum ConfirmButtonStyle {
  FILL = 'FILL',
  OUTLINE = 'OUTLINE',
}

@Component({
  selector: 'cl-confirm-button',
  templateUrl: './confirm-button.component.html',
  styleUrls: ['./confirm-button.component.scss']
})
export class ConfirmButtonComponent {

  ConfirmButtonStyle = ConfirmButtonStyle;

  @Input()
  duringLoading: boolean = false

  @Input()
  valid: boolean = true;

  @Input()
  disabled: boolean = false;

  @Input()
  showArrow: boolean = true;

  @Input()
  inactive: boolean = false;

  @Input()
  style: ConfirmButtonStyle = ConfirmButtonStyle.FILL;

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter<void>();

  confirmed() {
    if (this.disabled || this.inactive || !this.valid || this.duringLoading) {
      return
    }
    this.onConfirm.emit();
  }
}
