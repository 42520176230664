<cl-header></cl-header>

<main class="text-page">
  <div class="container">
    <section class="text-page__content">
      <div class="text-page__content__action">
        <a clBack [clBackFromError]="fromError" title="" class="text-page__content__action__back">
          <i class="text-page__content__action__back__icon"></i>Cofnij
        </a>
      </div>
      <cl-styled-content>
        <ng-content></ng-content>
      </cl-styled-content>
    </section>
  </div>
</main>

<cl-footer></cl-footer>
