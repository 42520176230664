import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserDevicesService} from '@shared/features/claim-account/user-devices/user-devices.service';
import {Observable} from 'rxjs';
import {UserDevice} from './../user-devices.model';
import {switchMapTo, tap} from 'rxjs/operators';

@Component({
  selector: 'cl-user-devices-modal',
  templateUrl: './user-devices-modal.component.html',
  styleUrls: ['./user-devices-modal.component.scss'],
})
export class UserDevicesModalComponent implements OnInit {

  devices$: Observable<UserDevice[]>
  duringLoading: boolean = true;

  constructor(public dialogDef: MatDialogRef<UserDevicesModalComponent>,
              private userDevicesService: UserDevicesService) {
  }

  ngOnInit() {
    this.devices$ = this.userDevicesService.loadDevices()
      .pipe(
        tap(() => this.duringLoading = false),
      );
  }

  removeDevice(device: UserDevice) {
    this.duringLoading = true;
    this.devices$ = this.userDevicesService.deleteUserDevice(device)
      .pipe(
        switchMapTo(this.userDevicesService.loadDevices()
          .pipe(
            tap(() => this.duringLoading = false),
          ))
      );
  }

  changeDeviceName(device: UserDevice, name: string) {
    this.duringLoading = true;
    this.devices$ = this.userDevicesService.changeUserDeviceName(device, name)
      .pipe(
        switchMapTo(this.userDevicesService.loadDevices()
          .pipe(
            tap(() => this.duringLoading = false),
          ))
      )
  }
}
