const isFunction = fn => typeof fn === "function";

const doUnsubscribe = subscription => {
  if (subscription && isFunction(subscription.unsubscribe)) {
    subscription.unsubscribe();
  }
};

const doUnsubscribeIfArray = subscriptionsArray => {
  if (Array.isArray(subscriptionsArray)) {
    subscriptionsArray.forEach(doUnsubscribe);
  }
};

export function AutoUnsubscribe({blackList = [], arrayName = "", event = "ngOnDestroy"} = {}) {
  return function (constructor: Function) {
    const original = constructor.prototype[event];

    if (!isFunction(original)) {
      constructor.prototype[event] = function () {
      }
    }

    constructor.prototype[event] = function () {
      if (isFunction(original)) {
        original.apply(this, arguments);
      }

      if (arrayName) {
        doUnsubscribeIfArray(this[arrayName]);
        return;
      }

      for (let propName in this) {
        if (blackList.includes(propName)) {
          continue;
        }
        const property = this[propName];
        doUnsubscribe(property);
      }
    };
  };
}
