import {Environment} from "@commonShared/models";

export const SET_ENVIRONMENT = '[Environment] Set environment';

export class SetEnvironment {
  static readonly type = SET_ENVIRONMENT;

  constructor(public environment: Environment) {
  }
}

