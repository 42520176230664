import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LogoutBlockingService {

  private logoutBlockade: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  logoutBlockade$: Observable<boolean> = this.logoutBlockade.asObservable();

  block() {
    this.logoutBlockade.next(true);
  }

  unblock() {
    this.logoutBlockade.next(false);
  }

}
