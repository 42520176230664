import {Component, OnInit} from '@angular/core';
import {GlobalErrorService} from "@shared/services";
import {Observable} from "rxjs";

@Component({
  selector: 'cl-global-error-messages',
  templateUrl: './global-error-messages.component.html',
  styleUrls: ['./global-error-messages.component.scss']
})
export class GlobalErrorMessagesComponent implements OnInit {

  errors$: Observable<string[]>;

  constructor(private globalErrorService: GlobalErrorService) {
  }

  ngOnInit() {
    this.errors$ = this.globalErrorService.errors$;
  }

  clear() {
    this.globalErrorService.clear()
  }

}
