<form [formGroup]="form" class="control-date" [class.control-date--disabled]="disabled">
  <span (click)="picker.open()" class="control-date__input-wrapper">
    <input
      formControlName="value"
      [disabled]="disabled"
      [min]="minDate"
      [max]="maxDate"
      [matDatepickerFilter]="dateFilter"
      matInput
      class="control-date__field"
      (dateChange)="writeValue($event.value)"
      [matDatepicker]="picker">
  </span>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon>unfold_more</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</form>
