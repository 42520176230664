import * as moment from 'moment';
import {Moment} from 'moment';

const MONDAY_WEEKDAY = 0;
const FRIDAY_WEEKDAY = 4;

export function workingDaysFilter(date: Moment): boolean {
  return date && isWorkingDay(date.weekday()) && !isHoliday(date)
}

export function getNextWorkingDays(daysToGet: number, excludeStartDate: moment.Moment | Date = moment()) {
  const startDate = toMoment(excludeStartDate);
  const dates = [];
  while (dates.length !== daysToGet) {
    startDate.add(1, 'days');
    if (!isWorkingDay(startDate.weekday())) {
      continue;
    }
    if (isHoliday(startDate)) {
      continue;
    }
    dates.push(getMoment(startDate));
  }
  return dates;
}

export function isToday(date: moment.Moment | Date): boolean {
  return getMoment().isSame(getMoment(date), 'day');
}

export function isBefore(date: moment.Moment | Date, hours: number, minutes: number = 0, seconds = 0): boolean {
  const momentDate = getMoment(date);
  const maxDate = momentDate.clone().hour(hours).minutes(minutes).seconds(seconds);
  return momentDate.isBefore(maxDate);
}

export function isWorkingDay(weekday): boolean {
  return weekday >= MONDAY_WEEKDAY && weekday <= FRIDAY_WEEKDAY;
}

export function isHoliday(date: moment.Moment): boolean {
  const holidays = [...getFixedHolidays(), ...getMovingHolidays()];
  return holidays.some(holiday => holiday.isSame(date, 'day'));
}

function getMoment(date: Date | moment.Moment = new Date()): moment.Moment {
  return moment(date).locale('PL-pl');
}

function toMoment(date: Date | moment.Moment): moment.Moment {
  return getMoment(date);
}

//musi być co roku uaktualniane: https://www.kalendarzswiat.pl/swieta/wolne_od_pracy/2021
function getMovingHolidays(): moment.Moment[] {
  const holidays = [
    //wielkanoc
    {
      day: 4,
      month: 4
    },
    //poniedziałek wielkanocy
    {
      day: 5,
      month: 4
    },
    //zesłanie ducha świętego
    {
      day: 23,
      month: 5
    },
    //boże ciało
    {
      day: 3,
      month: 6
    },
  ];
  return holidays
    .map(holiday => new Date(2020, holiday.month - 1, holiday.day))
    .map(date => getMoment(date));
}

function getFixedHolidays(): moment.Moment[] {
  const holidays = [
    //Nowy Rok, Świętej Bożej Rodzicielki
    {
      day: 1,
      month: 1,
    },
    //Trzech Króli (Objawienie Pańskie)
    {
      day: 6,
      month: 1,
    },
    //Święto Pracy
    {
      day: 1,
      month: 5,
    },
    //Święto Konstytucji 3 Maja
    {
      day: 3,
      month: 5,
    },
    //Święto Wojska Polskiego, Wniebowzięcie Najświętszej Maryi Panny
    {
      day: 15,
      month: 8,
    },
    //Wszystkich Świętych
    {
      day: 11,
      month: 11,
    },
    //Boże Narodzenie (pierwszy dzień)
    {
      day: 25,
      month: 12,
    },
    //Boże Narodzenie (drugi dzień)
    {
      day: 26,
      month: 12,
    },
  ];
  const currentYear = new Date().getFullYear();
  return holidays
    .map(holiday => new Date(currentYear, holiday.month - 1, holiday.day))
    .map(date => getMoment(date));
}
