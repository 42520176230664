import {AbstractControl} from '@angular/forms';
import {validateBankAccount} from './validation-commons';

/**
 * Założenie jest takie by funkcja walidatora zwracała obiekt z błędem o kluczu identycznym jak nazwa eksportowany obiektu do, którego funkcja jest podpięta
 */

export interface CustomValidator {
  message: (ValidationErrors) => string;
  validator: Function
}

export function bankAccountValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  if (validateBankAccount(control.value)) {
    return null;
  }
  return {
    bankAccountError: true
  }
}
