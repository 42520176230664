import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class HistoryService {

  private readonly homeUrl = '/';

  //mapa używana przez flow procesów związanych z FilterSecurityInterceptor
  private redirectMap: { [key: string]: string } = {};

  private urlHistory: string[] = [this.homeUrl];

  /*
  tu jest poprzednio dodany, w NavigationEnd dostajemy url po przekierowaniu, ale nigdzie nie ma przed przekierowaniem,
  tak więc url przed przekierowaniem to ostatni url po przekierowaniu
   */
  private lastUrl: string = null;

  public configureRedirectMap(redirectMap: { [key: string]: string }) {
    this.redirectMap = {
      ...this.redirectMap,
      ...redirectMap,
    }
  }

  public clear() {
    this.lastUrl = this.homeUrl;
    this.urlHistory = [];
  }

  public add(url: string) {
    if (!url) {
      return;
    }
    if (this.lastUrl) {
      const lastAddedUrl = this.urlHistory[this.urlHistory.length - 1];
      if (lastAddedUrl !== this.lastUrl) {
        this.urlHistory.push(this.lastUrl);
      }
    }
    this.lastUrl = this.resolvePreviousSaveUrl(url);
  }

  private resolvePreviousSaveUrl(url: string): string {
    for (const [key, value] of Object.entries(this.redirectMap)) {
      if (url.includes(key)) {
        return value;
      }
    }
    return url;
  }

  public popLatest(): string {
    if (this.urlHistory.length) {
      return this.urlHistory.pop();
    }
    return this.homeUrl;
  }

}
