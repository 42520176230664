import {MatFormFieldControl} from "@angular/material/form-field";

/**
 * Konieczne do zaimplementowania gdy własny komponent wrapujący MatFormFieldControl używa ControlValueAccessor
 * i będzie używany wraz z <cl-form-field>. Przykładowa implementacja w datepicker.component.ts
 * 1. należy dodać tą klasę do tablicy providers: @Component({ providers: [ { provide:CustomControl, useExisting: forwardRef(() => KlasaKomponentu ) } ] }
 * 2. należy dodać pole _internalControl w kontrolerze z użyciem: @ViewChild(MatFormFieldControl, {static: true})
 */
export class CustomControl {
  _internalControl: MatFormFieldControl<any> = null;
}
