import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

export enum LogoMode {
  NORMAL = 'NORMAL',
  GENERALI_AGRO = 'GENERALI_AGRO',
}

@Injectable({
  providedIn: "root"
})
export class LogoModeService {

  private logoMode: BehaviorSubject<LogoMode> = new BehaviorSubject<LogoMode>(LogoMode.NORMAL);
  logoMode$: Observable<LogoMode> = this.logoMode.asObservable();

  update(mode: LogoMode) {
    this.logoMode.next(mode);
  }

  reset() {
    this.logoMode.next(LogoMode.NORMAL);
  }

}
