import {Component} from "@angular/core";

@Component({
  selector: 'cl-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

}
