export enum BrandName {
  GENERALI = 'GENERALI',
  PROAMA = 'PROAMA',
}

export interface Brand {
  name: BrandName;
  naturalName: string;
  gtm: string;
  homepageUrl: string;
  [key: string]: any;
}

export const brands = {
  [BrandName.GENERALI]: {
    name: BrandName.GENERALI,
    naturalName: 'Generali',
    gtm: 'GTM-KM3BSN',
    homepageUrl: 'https://www.generali.pl/'
  },
  [BrandName.PROAMA]: {
    name: BrandName.PROAMA,
    naturalName: 'Proama',
    gtm: 'GTM-KM3BSN',
    homepageUrl: 'https://www.proama.pl/'
  },
};
