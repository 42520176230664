import {Pipe, PipeTransform} from '@angular/core';
import {UserDevice} from './user-devices.model';

@Pipe({
  name: 'userDeviceDateOrder'
})
export class UserDeviceDateOrderPipe implements PipeTransform {
  transform(device: UserDevice, ...args: any[]): Date {
    if (device.createdDate) {
      return device.createdDate;
    }
    if (device.confirmationDate) {
      return device.confirmationDate;
    }
    return device.lastModifiedTime;
  }

}
