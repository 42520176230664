<cl-popup [dialog]="dialogDef" [title]="context.file.fileSaved.name">

  <div class="file-upload-view-dialog-panel">

    <div *ngIf="isPdf() else image" class="file-upload-view-dialog-panel__pdf file-upload-view-dialog-panel__pdf--loaded">
      <ng2-pdfjs-viewer [pdfSrc]="getSrc()"
                        [externalWindow]="false"
                        [downloadFileName]="context.file.fileSaved.name"
                        [openFile]="false"
                        [viewBookmark]="false"
                        [download]="false"
                        zoom="page-width">
      </ng2-pdfjs-viewer>
    </div>

    <ng-template #image>
      <div class="file-upload-view-dialog-panel__image">
        <img [src]="getSrc()">
      </div>
    </ng-template>

  </div>

</cl-popup>
