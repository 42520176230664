import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserDevice} from './../user-devices.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'cl-user-device',
  templateUrl: './user-device.component.html',
  styleUrls: ['./user-device.component.scss'],
})
export class UserDeviceComponent implements OnInit {

  private readonly mobileDevices: string[] = ['android', 'ios'];

  isDeviceComputer: boolean;
  shouldDeviceRemovalVisible: boolean = false;
  isEditNameMode: boolean = false;
  deviceForm: FormGroup;

  @Input()
  device: UserDevice;

  @Output()
  removed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  nameChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
    this.specifyDeviceType();
  }

  private initForm() {
    this.deviceForm = this.fb.group({
      name: [this.device.userDeviceName, [Validators.required]]
    });
  }

  private specifyDeviceType() {
    const normalizeUserAgent = this.device.userAgent.toLowerCase();
    this.isDeviceComputer = !this.mobileDevices.some(device => normalizeUserAgent.includes(device));
  }

  showRemoveConfirm() {
    this.shouldDeviceRemovalVisible = true;
  }

  hideRemoveConfirm() {
    this.shouldDeviceRemovalVisible = false;
  }

  removeDevice() {
    if (!this.device.current) {
      this.removed.emit()
    }
  }

  editName() {
    this.isEditNameMode = true;
  }

  changeName() {
    this.nameChanged.emit(this.deviceForm.get('name').value);
  }

  cancelEditName() {
    this.isEditNameMode = false;
    this.deviceForm.get('name').setValue(this.device.userDeviceName);
  }
}
