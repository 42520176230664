import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Environment, EnvironmentType} from "@commonShared/models";
import {SetEnvironment} from "@commonShared/store/actions/common-shared.action";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private readonly DEV_ENV: Environment = {
    type: EnvironmentType.DEV,
    showUnderConstructionSign: true,
  };

  private readonly TEST_ENV: Environment = {
    type: EnvironmentType.TEST,
    showUnderConstructionSign: true,
  };

  private readonly PROD_ENV: Environment = {
    type: EnvironmentType.PROD,
    showUnderConstructionSign: false,
  };

  private readonly envTestUrlParts = [
    'hathort2',
    'hathort1',
    'hathoru1',
    'hathoru2',
    'hathord3',
    'hathord4',
    'hathorpostprod',
    'hathorpreprod',
    'portaltest',
    'pl000000'
  ]

  constructor(private store: Store) {
  }

  setAndRetrieveEnvironmentFromUrl(url: string): Environment {
    const environment: Environment = this.getCurrentEnvironment(url);
    this.setEnvironment(environment);
    return environment;
  }

  setEnvironment(environment: Environment) {
    this.store.dispatch(new SetEnvironment(environment));
  }

  private getCurrentEnvironment(url: string): Environment {
    if (url.indexOf("localhost") > 0) {
      return this.DEV_ENV;
    }

    for (const testEnvUrlPart of this.envTestUrlParts) {
      if (url.indexOf(testEnvUrlPart) > 0) {
        return this.TEST_ENV;
      }
    }

    return this.PROD_ENV;
  }
}
