import {NgModule} from '@angular/core';

import {
  BackPanelComponent,
  BackWithExternalLinkPanelComponent,
  ConfirmButtonComponent,
  CustomErrorComponent,
  ErrorComponent,
  ErrorMessageComponent,
  FooterComponent,
  GlobalErrorMessagesComponent,
  HeaderComponent,
  InfoBarComponent,
  LoadingSpinner,
  NextPanelComponent,
  PopupComponent,
  SelectLocationComponent,
  ServerErrorComponent,
  StyledContentComponent,
  TechnicalErrorComponent,
  TextPageLayoutComponent,
  TooltipComponent,
} from './components';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {NgxsModule} from '@ngxs/store';
import {SharedState} from '@shared/store';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {TooltipModule} from '@cloudfactorydk/ng2-tooltip-directive';
import {NgxMaskModule} from 'ngx-mask';
import {BrandChooserComponent, EnvironmentInfo} from './devs';
import {
  BackDirective,
  ClickStopPropagation,
  EmailDirective,
  FirstUpperDirective,
  LocateMeDirective,
  ShowPasswordToggleDirective,
  TransformMaskDirective,
  VariableDirective
} from './directives';
import {
  FileUploadedViewDialogContentComponent,
  FileUploadViewDialogContentComponent,
  UserDeviceComponent,
  UserDeviceDateOrderPipe,
  UserDevicesModalComponent
} from '@shared/features';
import {DatepickerComponent, EditableFieldComponent, FormFieldComponent} from '@shared/form';
import {BankAccountPipe, FilterPipe} from '@shared/pipes';
import {ConfirmModalComponent} from "@shared/modal/generic";
import {GoogleMapsModule, MapGeocoder} from "@angular/google-maps";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {MoneyPipe, PhonePipe, ValuesPipe} from "@commonShared/pipes";
import {PhoneDirective} from "@commonShared/directives/phone.directive";

@NgModule({
  declarations: [
    CustomErrorComponent,
    ServerErrorComponent,
    BrandChooserComponent,
    EnvironmentInfo,
    TechnicalErrorComponent,
    ErrorComponent,
    FooterComponent,
    HeaderComponent,
    ErrorMessageComponent,
    TooltipComponent,
    InfoBarComponent,
    TextPageLayoutComponent,
    UserDevicesModalComponent,
    PopupComponent,
    UserDeviceComponent,
    EditableFieldComponent,
    StyledContentComponent,
    FormFieldComponent,
    ConfirmButtonComponent,
    DatepickerComponent,
    FileUploadedViewDialogContentComponent,
    FileUploadViewDialogContentComponent,
    BackPanelComponent,
    BackWithExternalLinkPanelComponent,
    NextPanelComponent,
    ShowPasswordToggleDirective,
    BackDirective,
    UserDeviceDateOrderPipe,
    PhonePipe,
    BankAccountPipe,
    FilterPipe,
    MoneyPipe,
    ValuesPipe,
    VariableDirective,
    LoadingSpinner,
    PhoneDirective,
    EmailDirective,
    TransformMaskDirective,
    FirstUpperDirective,
    ClickStopPropagation,
    LocateMeDirective,
    BackWithExternalLinkPanelComponent,
    ConfirmModalComponent,
    GlobalErrorMessagesComponent,
    SelectLocationComponent,
  ],
  exports: [
    CustomErrorComponent,
    ServerErrorComponent,
    BrandChooserComponent,
    EnvironmentInfo,
    TechnicalErrorComponent,
    ErrorComponent,
    FooterComponent,
    HeaderComponent,
    ErrorMessageComponent,
    TooltipComponent,
    InfoBarComponent,
    TextPageLayoutComponent,
    UserDevicesModalComponent,
    PopupComponent,
    UserDeviceComponent,
    EditableFieldComponent,
    StyledContentComponent,
    FormFieldComponent,
    ConfirmButtonComponent,
    DatepickerComponent,
    FileUploadedViewDialogContentComponent,
    FileUploadViewDialogContentComponent,
    BackPanelComponent,
    NextPanelComponent,
    ShowPasswordToggleDirective,
    BackDirective,
    UserDeviceDateOrderPipe,
    MaterialModule,
    TooltipModule,
    LoadingSpinner,
    PhonePipe,
    BankAccountPipe,
    FilterPipe,
    MoneyPipe,
    ValuesPipe,
    VariableDirective,
    PhoneDirective,
    EmailDirective,
    TransformMaskDirective,
    FirstUpperDirective,
    ClickStopPropagation,
    LocateMeDirective,
    BackWithExternalLinkPanelComponent,
    ConfirmModalComponent,
    GlobalErrorMessagesComponent,
    SelectLocationComponent,
  ],
  imports: [
    NgxsModule.forFeature([SharedState]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    PdfJsViewerModule,
    TooltipModule,
    NgxMaskModule.forChild(),
    GoogleMapsModule,
    GooglePlaceModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        maxWidth: '96.8rem',
        width: '100%',
        maxHeight: '80vh',
        backdropClass: 'app-modal-backdrop',
        id: 'app-modal',
        panelClass: 'app-panel',
        disableClose: false,
      }
    },
    MapGeocoder,
  ]
})
export class SharedModule {
}
